import { Component } from '@angular/core';
import { AuthService } from '../_common/services/auth.service';
import { UserService } from '../_common';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {
  visible: boolean = false;

  constructor(private authService: AuthService, private userService: UserService) { }

  showDialog() {
    this.visible = true;
  }

  onContinue(): void {
    this.visible = false;
  }

  onLogout(): void {
    this.userService.logout();
    window.location.href = '/login';
  }

}
