import { Router } from "@angular/router";

import { Injectable, Component } from "@angular/core";
import { FormDefault, Model } from "./model.view";
import { View } from "./view.component";
import { SysService } from "../services/sys.service";
import { UntypedFormBuilder } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { ApiService } from "../../_common/services/api.service";
import { Location } from '@angular/common';
// @Injectable()
@Injectable()
export class ViewFactory {

  constructor(
    private router: Router,
    private location: Location,
    public sysService: SysService,
    private apiService: ApiService,
    private confirmationService: ConfirmationService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  newView() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.model = this.emptyForm();
    return v;
  }

  newViewPopup() {
    const v = new View(this.router, this.location, this.sysService, this.apiService, this.confirmationService);
    v.setPopup(true);
    v.model = this.emptyForm();
    return v;
  }

  private emptyForm() {
    return new Model(this.formBuilder.group({
      id: [],
      noformdefined: []
    }), this.sysService, new FormDefault(), '');
  }
}
