import { ChangeDetectorRef, EventEmitter, Injectable, OnInit, Output } from '@angular/core';

import { MessageService, PrimeNGConfig, MenuItem, Message } from 'primeng/api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { NavigationExtras, Router } from '@angular/router';
import { AppConfig, AppConstants } from 'src/app/_config';
import { MenuService } from '../data/menu.service';
@Injectable()
// @Injectable({  providedIn: 'root'})
export class SysService {

  get loading(): boolean {
    return this.load;
  }

  constructor(
    private messageService: MessageService,
    private apiService: ApiService,
    private userService: UserService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    public http: HttpClient,
    public router: Router,) {
    this.primengConfig.ripple = true;

  }
  popup = false; //transfered from view or viewpopup
  theme = 'theme-blue';
  logo = 'logo.png';
  siteContext: string | undefined;
  rows = 20;
  rowsPerPageOptions = [10, 30, 50, 100, 200];

  menuItems: MenuItem[] = []; // Menus for the user
  username: string | undefined;
  siteList: any[] | undefined;
  // Menus for the user
  // loading = false;
  public submitted = false;
  load = false;
  user: any;
  timerflag = false;
  timerRun: string | undefined;
  timerRun1: any;
  //@Output() timerRun: EventEmitter<string> = new EventEmitter<string>();
  // public timerRun = '0:0:0';
  static scrollTo(v = 0): void {
    document.querySelector('body')?.scroll({
      top: v,
      left: 0,
      behavior: 'smooth'
    });
  }
  setLoading(loading: boolean): void {
    this.load = loading;
  }

  async appRefresh(): Promise<void> {
    if (this.menuItems.length === 0) {
      await this.appInit();
    }
  }



  async appInit(): Promise<void> {
    this.menuItems = [];
    this.username = '';
    this.siteList = [];
    this.siteContext = '';
    this.user = this.getUser();
    if (this.isLoggedIn()) {
      const authUser = localStorage.getItem('auth-user');
      if (authUser) {
        const user = JSON.parse(authUser);
      }
      if (this.isAdmin()) {
        this.menuItems = MenuService.getMenuUser(this.isAdmin(), this.userService, this.router, this.messageService);
      }
    }
  }

  isAdmin(): any {
    if (this.isLoggedIn()) {
      const user = this.getUser()?.role_id
      return user === 1
    } else {
      const user = this.getUser()?.role_id
      return user === 1
    }

  }


  isCoordinator(): any {
    if (this.isLoggedIn()) {
      const user = this.getUser().role_id
      return user === 3
    }

  }

  private getKey(key: string): string {
    if (key === '') {
      if (this.popup === true) return 'popmsgs';
      if (this.popup === false) return 'msgs';
    }
    return key;
  }

  success(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.successAdd(summary, detail, this.getKey(key));
  }

  successAdd(summary: string, detail: string = '', k: string = ''): void {
    this.clearUnload();
    if (summary && summary !== '') {
      let key: string = this.getKey(k);
      this.messageService.add({
        severity: 'success',
        summary,
        detail,
        key,
        life: 30000  // Show the toast for 30 seconds
      });
    }
  }

  error(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.errorAdd(summary, detail, this.getKey(key));
  }

  errorAdd(summary: string, detail: string = '', k: string = ''): void {
    this.clearUnload();
    if (summary && summary !== '') {
      let key: string = this.getKey(k);
      this.messageService.add({ severity: 'error', summary, detail, key, life: 30000 });
    }
  }

  info(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.infoAdd(summary, detail, this.getKey(key));
  }

  infoAdd(summary: string, detail: string = '', k: string = '') {
    this.clearUnload();
    if (summary && summary !== '') {
      let key: string = this.getKey(k);
      this.messageService.add({ severity: 'info', summary, detail, key });
    }
  }

  warn(summary: string, detail: string = '', key: string = ''): void {
    this.messageService.clear();
    this.warnAdd(summary, detail, this.getKey(key));
  }

  warnAdd(summary: string, detail: string = '', k: string = ''): void {
    this.clearUnload();
    if (summary && summary !== '') {
      let key: string = this.getKey(k);
      this.messageService.add({ severity: 'warn', summary, detail, key });
    }
  }

  clearUnload(): void {
    // this.messageService.clear();
    this.load = false;
  }
  clear(keep: boolean = false): void {
    this.clearUnload();
    if (keep === false) {
      this.messageService.clear();
    }
    this.submitted = false;
  }

  navigate(url: any[], keep: boolean = false, extras?: NavigationExtras): void {
    this.clear(keep);
    this.router.navigate(url);
  }

  getUser(): any {
    this.user = this.userService.getUser();
    return this.user;
  }


  isLoggedIn(): boolean {
    return this.userService.getUser();
  }



  async signOut(): Promise<void> {
    this.user = null;
    this.userService.signOut();
    this.navigate([AppConfig.logout]);
  }


  handleError(err: any, mess: string = 'DEFAULT'): void {
    this.load = false;
    if (err.status === 422) {
      if (err.error && err.error.length > 0) {
        for (let i = 0; i < err.error.length; i++) {
          this.errorAdd(err.error[i]);
        }
      } else {
        this.error(AppConstants.validationFailed);
      }
    }
    else if ([400].includes(err.status)) {
      this.error(AppConstants.err400);
    }
    else {
      this.error(mess === 'DEFAULT' ? AppConstants.errUnknow : mess);
    }
  }


  log(servicePath: string, err: HttpErrorResponse) {
    // console.log("err", err)
    if (err) {
      const payload = {
        api_url: err.url,
        error_code: err.status,
        error_message: err.message
      }
      let payloads = JSON.stringify(payload);
      // let e = `{error_message:"${JSON.stringify(err.message)}"}`
      // console.log(payloads);
      // this.apiService.post(`${servicePath}`, `${payloads}`).toPromise().then(data => { })
    }
  }







  changeTheme(styleName: string, logo: string): void {

    this.theme = styleName;
    this.logo = logo;
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById('theme-style') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${styleName}.css`;
    } else {
      const style = document.createElement('link');
      style.id = 'theme-style';
      style.rel = 'stylesheet';
      // style.type = 'text/stylesheet';
      style.href = `${styleName}.css`;

      head.appendChild(style);
    }

  }

  link(path: any, router: { navigateByUrl: (arg0: string, arg1: { skipLocationChange: boolean; }) => Promise<any>; navigate: (arg0: any[]) => any; }) {
    SysService.scrollTo(0);
    this.messageService.clear();
    router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([path]));
  }
  timerStart(minute: number) {
    this.timerflag = true;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.timerRun = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        //console.log("finished");
        clearInterval(timer);
      }
      if (localStorage.getItem('isTimer') === 'false') {
        clearInterval(timer)
      }
    }, 1000);
  }

}
