import { HttpUrlEncodingCodec } from './http-url-encoding-codec';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SysService } from '../services/sys.service';
import * as _ from 'lodash';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

export class Model {
  [x: string]: any;

  formData: FormData | null | undefined;
  id: any;
  apiUrl: string; //Api url of the service
  form: UntypedFormGroup;
  list: any = [];
  view: any;
  selection: any = []; //filtered or selected list
  private params: HttpParams | undefined;
  total = 0;
  state = 'new';
  islist = true;    //list
  isform = false;   //new and edit
  isformview = false; //new, edit and view
  formDefault: FormDefault | any;
  searchTxt: string = '';
  isValid: any;

  constructor(form: UntypedFormGroup, public sysService: SysService, formDefault?: FormDefault, apiUrl: string = '') {
    this.form = form;
    this.formDefault = formDefault;
    this.apiUrl = (apiUrl.length === 0) ? environment.appApi : apiUrl;
  }


  get p() {
    if (!this.params) {
      this.params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    }
    return this.params;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  param(key: any) {
    return this.setParam(key, this.get(key));
  }

  setParam(key: any, value: any) {
    if (value === null) {
      //ignore
    } else if (value !== undefined) {
      this.params = this.p.set(key, value);
    }
    return value;
  }

  // setParamNotNull(key: any, value: any) {
  //   if (value === null) {
  //     //ignore
  //   } else if (value !== undefined) {
  //     this.params = this.p.set(key, value);
  //   }
  //    return value;
  // }

  field(name: string) {
    return this.form.controls[name];
  }

  set(name: string, value: any) {
    return this.field(name)?.setValue(value);
  }



  get(name: string, defaultValue: any = null) {
    const f = this.field(name);
    if (!f || f === null) {
      return defaultValue;
    }

    const val = f.value;
    if (val !== undefined && val !== null) {
      return val;
    }
    return defaultValue;
  }

  reset() {
    this.id = null;
    this.form.reset();
    this.formData = null;
  }

  clearParams() {
    if (this.params) {
      this.params = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
    }
  }

  equals(name: string, value: string) {
    const v = this.get(name);
    if (v !== undefined && v !== null) {
      return (('' + v) === value);
    }
    return false;
  }

  isEmpty(name: string) {
    const v = this.get(name);
    if (v !== undefined && v !== null) {
      return (('' + v).trim().length === 0);
    }
    return true;
  }

  isEmptyList(list = this.list) {
    if (list) {
      if (list instanceof Array) {
        return (list.length === 0);
      }
    }
    return true;
  }


  // Validation methods
  errors(name: string, f: any = null) {
    if (this.sysService.submitted) {
      const f1 = !f ? this.field(name) : f;
      if (!f1) {
        console.log(name + 'Not found');
      }
      return (f1?.errors !== null);
    }
    else {
      return false;
    }
  }
  required(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['required'] : false;
  }
  email(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['email'] : false;
  }
  pattern(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['pattern'] : false;
  }
  minlength(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['minlength'] : false;
  }
  maxlength(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['maxlength'] : false;
  }
  compose(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['compose'] : false;

  }
  match(name: string) {
    const f = this.field(name);
    return this.errors(name, f) ? f.errors?.['match'] : false;
  }

  hasError(name: string) {
    return this.sysService.submitted ? this.form.hasError(name) : false;
  }

  isList() {
    return this.islist;
  }

  isForm() {
    return this.isform;
  }

  isNew() {
    return this.state === 'new';
  }

  isEdit() {
    return this.state === 'edit';
  }

  isView() {
    return this.state === 'view';
  }
  isHidden() {
    return this.state === 'hide';
  }
  /**
  Set data on edit to existing form, will not reset previous form data
   */
  setToNextEdit(data: any, form: any = this.form, reset: boolean = false) {
    this.fillIt(data, form, reset);
    return this;
  }

  /**
   Set data on edit to existing form, will not reset previous form data
   */
  updateFormView(data: any, form: any = this.form, reset: boolean = false) {
    this.fillIt(data, form, reset, -1);
    return this;
  }

  setToForm(data: any, form: any = this.form, reset: boolean = false) {
    this.fillIt(data, form, reset, 0);
    return this;
  }


  find(field: string, value: any) {
    let i = 0;
    for (const item of this.list) {
      if (item[field] === value) { //always id should be there
        return new Item(i, item);
      }
      i++;
    }
    return new Item(i, null);
  }
  /**
   Set data to list on save.
   */
   async setFormToList(field: string, value: any = null, data: any = this.form.value, isedit:any) {
    if (value) {
      const item: Item = this.find(field, value);
      item.value = data;
      this.setToList(item, isedit);
    } else {
      if (this.list instanceof Array) {
          this.list.unshift(data); //add to first
        // this.list.push( data);
      }
    }
    return this;
  }

  setToList(item: Item, isedit:any) {
    if (this.list instanceof Array) {
      if(isedit){
      this.list.splice(item.index, 1, item.value);
      }else{
      this.list.unshift(item.value); 
      }

    }
  }

  addFormArray(name: string, arrayFormGroup: UntypedFormGroup, mainFrm: any = this.form) {
    const a = mainFrm.controls[name] as UntypedFormArray;
    a.push(arrayFormGroup);
  }

  delFormArray(name: string, index: number, mainFrm: any = this.form) {
    const a = mainFrm.controls[name] as UntypedFormArray;
    a.removeAt(index);
  }

  setParamFromModel(model: Model) {
    this.clearParams();
    let val: any;
    for (const item in model.form.controls) {
      if (item) {
        val = model.form.controls[item].value;
        if (val) {
          this.setToParam(item, val);
        }
        else if (val === null) {
          //ignore
        } else if (val !== undefined) {
          this.setToParam(item, val);
        }
      }
    }
  }


  get invalid() {
    return this.form.invalid;
  }

  get valid() {
    return this.form.valid;
  }

  setDefult(index: number, value: any) {
    this.formDefault.values[index] = value;
  }

  addDefault(field: string, value: any) {
    this.formDefault.fields.push(field);
    this.formDefault.values.push(value);
  }

  value() {
    return this.form.value;
  }

  getFormData() {
    return this.formData;
  }
  makeFormData() {
    if (!this.formData) { this.formData = new FormData(); }
    return this.formData;
  }

  setFile(files: FileList | null, key: string = 'file') {
    if (files) {
      const file = files.item(0);
      if (file && files.length === 1) {
        this.makeFormData().set(key, file, file.name);
      }
      if (files.length > 1) {
        for (let i = 0; i < files.length; i++) {
          this.makeFormData().append(key, files[i]);
        }
      }
    } else {
      this.makeFormData().set(key, '');
    }
  }

  setMultipart() {
    this.makeFormData();
  }

  //   onSelectAllChange(event) {
  //     const checked = event.checked;
  //     if (checked) {
  //       this.selectAll = true;
  //     }
  //     else {
  //         this.selection = [];
  //         this.selectAll = false;
  //     }
  // }

  private setFormArray(name: string, values: any[], form: any = this.form) {
    const formArray = new UntypedFormArray([]);
    if (values) {
      let i = 0;
      values.forEach(v => {
        if (form && form.controls[name] && form.controls[name].controls) { //&& !this.isEmptyList(form.controls[name].controls[0])
          const frm = _.cloneDeep(form.controls[name].controls[0]);
          if (frm) {
            this.fillIt(v, frm, false, i++);
            formArray.push(frm);
          }
        }
      });
      if (i > 0) { form.setControl(name, formArray); }
    } else {
      if (form && form.controls[name] && form.controls[name].controls) { //&& !this.isEmptyList(form.controls[name].controls[0])
        const frm = _.cloneDeep(form.controls[name].controls[0]);
        if (frm) {
          this.fillIt(null, frm, false, 0);
          formArray.push(frm);
        }
      }
      form.setControl(name, formArray);
    }

  }

  private fillIt(row: any, frm: any, reset: boolean = false, index: number = -1) {
    if (index === -1) { this.view = row; }
    if (row) {
      if (reset) {
        this.reset();
      }
      let val: any;
      for (const item in frm.controls) {
        if (item) {
          val = row[item];
          if (val) {
            this.setToFill(val, item, frm, reset, index++);
          }
          else if (val === null) {
            frm.controls[item].setValue(null);
          } else if (val !== undefined) {
            this.setToFill(val, item, frm, reset, index++);
          }
        }
      }
    }
  }

  private setToFill(val: any, item: any, frm: any, reset: boolean, index: number) {
    if (val instanceof UntypedFormGroup) {
      this.fillIt(val, frm.controls[item], reset, index++);
    }
    else if (val instanceof Array) {
      this.setFormArray(item, val, frm);
    }
    else {
      frm.controls[item].setValue(val);
    }
  }

  private setToParam(item: string, val: any) {
    val = ('' + val).trim();
    if (val.length !== 0) {
      this.setParam(item, val);
    }
  }
}

export class FormDefault {
  fields: any;
  values: any;
  constructor(fields: any = [], values: any = []) {
    this.fields = fields;
    this.values = values;
  }
}

export class Item {
  value: any;
  index: any;
  constructor(index: number, value: any) {
    this.index = index;
    this.value = value;
  }
  set(field: string | number, val: any) {
    if (this.value) { this.value[field] = val; }
  }
}

