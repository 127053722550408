<div class="page-wrapper margin-left">
    <img class="text-center logo-img" src="assets/images/mainlogo.png" alt="MaMORs"
        style="height: 100px !important; width: 250px !important;">
</div>
<div class="container">
    <h1>Terms of Service</h1>

    <p>Effective Date: January 1, 2024</p>

    <h2>1. Introduction</h2>
    <p>Welcome to StrokePreven App. By accessing or using our website or services, you agree to
        comply with and be bound by these Terms of Service. If you do not agree to these Terms, please do not
        use our services.</p>

    <h2>2. Changes to Terms</h2>
    <p>We reserve the right to modify or revise these Terms at any time. Any changes will be posted on this page with an
        updated effective date. We encourage you to review these Terms regularly to stay informed of any changes.</p>

    <h2>3. User Responsibilities</h2>
    <ul>
        <li>You must be at least 18 years old to use our services.</li>
        <li>You agree to use our website and services in accordance with all applicable local, state, and international
            laws.</li>
        <li>You are responsible for maintaining the confidentiality of your account information and for all activities
            under your account.</li>
    </ul>

    <h2>4. Prohibited Activities</h2>
    <p>You may not use our services for any unlawful or prohibited purpose, including but not limited to:</p>
    <ul>
        <li>Violating any applicable law, regulation, or third-party rights.</li>
        <li>Distributing malicious software or attempting to gain unauthorized access to our systems.</li>
        <li>Engaging in any activity that interferes with or disrupts the services or the experience of other users.
        </li>
    </ul>

    <h2>5. Termination</h2>
    <p>We may suspend or terminate your access to our services at any time, without notice, if you violate these Terms
        or for any other reason deemed necessary by us.</p>

    <h2>6. Limitation of Liability</h2>
    <p>To the fullest extent permitted by law, StrokePreven App will not be liable for any indirect, incidental,
        special, or consequential damages arising from your use of our services.</p>

    <h2>7. Governing Law</h2>
    <p>These Terms will be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard
        to its conflict of law principles.</p>

    <h2>8. Contact Information</h2>
    <p>If you have any questions or concerns regarding these Terms, please contact us at:</p>
    <p>Email: <a href="mailto:medicationadherence2021@gmail.com">medicationadherence2021&#64;gmail.com</a></p>

    <div class="footer">
        <p>&copy; 2024 StrokePreven App. All Rights Reserved.</p>
        <p>By using our services, you agree to our <a href="/privacy">Privacy Policy</a>.</p>
    </div>
</div>