import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { canActivate } from './_common/helpers/auth.guard'; // Ensure the path is correct

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [canActivate] // Protect the home route
  },
  {
    path: 'hospitals',
    loadChildren: () => import('./hospitals/hospitals.module').then(m => m.HospitalsModule),
    canActivate: [canActivate] // Protect the hospitals route
  },
  {
    path: 'patients',
    loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),
    canActivate: [canActivate] // Protect the patients route
  },
  {
    path: 'users',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
    canActivate: [canActivate] // Protect the users route
  },
  {
    path: 'doctors',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
    canActivate: [canActivate] // Protect the doctors route
  },
  {
    path: 'scale',
    loadChildren: () => import('./scale/scale.module').then(m => m.ScaleModule),
    canActivate: [canActivate] // Protect the scale route
  },
  {
    path: 'infographics',
    loadChildren: () => import('./infographics/infographics.module').then(m => m.InfographicsModule),
    canActivate: [canActivate] // Protect the infographics route
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [canActivate] // Protect the reports route
  },
  {
    path: 'tests',
    loadChildren: () => import('./tests/tests.module').then(m => m.TestsModule),
    canActivate: [canActivate] // Protect the tests route
  },
  {
    path: 'medicine',
    loadChildren: () => import('./medicine/medicine.module').then(m => m.MedicineModule),
    canActivate: [canActivate] // Protect the medicine route
  },
  {
    path: 'medicine-details',
    loadChildren: () => import('./medicineDetails/medicineDetails.module').then(m => m.MedicineDetailsModule),
    canActivate: [canActivate] // Protect the medicine-details route
  },
  {
    path: 'medicines',
    loadChildren: () => import('./master-medicine/master-medicine.module').then(m => m.MasterMedicineModule),
    canActivate: [canActivate] // Protect the medicines route
  },
  {
    path: 'tips/new',
    loadChildren: () => import('./tips/tips.module').then(m => m.TipsModule),
    canActivate: [canActivate] // Protect the medicines route
  },

  // Redirect any invalid route to the login page
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
