import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { SysService } from '../services/sys.service';
import { AppConfig } from 'src/app/_config';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const sysService = inject(SysService);
  const router = inject(Router);

  // Check if the user is logged in
  if (!sysService.isLoggedIn()) {
    sysService.error('Access Denied , Login is Required to Access!');

    // Return a UrlTree to navigate to the login page instead of directly calling `router.navigate`
    return router.createUrlTree([AppConfig.login], {
      queryParams: { returnUrl: state.url }
    });
  }

  return true; // Allow navigation if logged in
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return canActivate(route, state); // Reuse the same logic for child routes
};
