import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppConstants } from 'src/app/_config';
import { Model } from '../components/model.view';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }


  toPdfTable(name: string, heads: any, rows: any) {
    const doc = new jsPDF()
    autoTable(doc, { head: heads, body: rows })
    doc.save(name + '.pdf');
  }

  toPdfTablePrint(name: string, heads: any, rows: any) {
    const doc = new jsPDF()
    autoTable(doc, { head: heads, body: rows })
    doc.autoPrint({ variant: "non-conform" });
    doc.output('pdfobjectnewwindow');
  }

  toXlsx(name: string, headers: any, list: any) {
    import("xlsx").then(xlsx => {
      list.unshift(headers);
      const worksheet = xlsx.utils.aoa_to_sheet(list);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, name, '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8');
    });
  }

  toCsv(name: string, headers: any, list: any) {
    import("xlsx").then(xlsx => {
      list.unshift(headers);
      const worksheet = xlsx.utils.aoa_to_sheet(list);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, name, ".csv", 'text/csv;charset=UTF-8');
    });
  }

  toCopyClipboard(headers: any, list: any) {
    const config = new ExportConfig();
    let body: any = [];

    const head = headers ? this._join(headers, config) + config.newLine : '';
    //const footer = this.footer ? config.newLine+this._join( this.footer, config ) : '';

    for (var i = 0, len = list.length; i < len; i++) {
      body.push(this._join(list[i], config));
    }
    return head + body.join(config.newLine); //+ + footer;
  }

  private _join(a: any, config: ExportConfig) {
    var s = '';
    // If there is a field boundary, then we might need to escape it in
    // the source data
    let v = '';
    for (var i = 0, ien = a.length; i < ien; i++) {
      if (i > 0) {
        s += config.separator;
      }
      v = a[i];
      if (!v) {
        v = '';
      }
      s += config.boundary ?
        config.boundary + ('' + v).replace(config.reBoundary, config.escapeChar + config.boundary) + config.boundary : v;
    }
    return s;
  }

  private saveAsExcelFile(buffer: any, fileName: string, extension: string, type: string): void {
    const data: Blob = new Blob([buffer], {
      type: type
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + extension);
  }

  public static downloadFile(url: any) {
    var filename = url.split('/').pop();
    FileSaver.saveAs(url, filename);
  }

}

class ExportConfig {
  newLine = navigator.userAgent.match(/Windows/) ? '\r\n' : '\n';
  boundary = '';
  separator = '\t';
  reBoundary = new RegExp(this.boundary, 'g');
  escapeChar = '';
}