import { Component } from '@angular/core';
import {
  Chart,
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  BarController,
  Tooltip,
  Legend,
  PieController,
  ArcElement,
} from 'chart.js';
import { UserService } from 'src/app/_common/services/user.service';

import {
  SysService,
  StaticService,
  ViewFactory,
  Model,
  View,
} from '../../_common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  private dashboardView: View = this.viewFactory.newView();
  dashboardData: any;
  hospitals: any[] = [];
selectedhospital: any
private lineChart: Chart | null = null;
private barChart: Chart | null = null;
  constructor(
    private userService: UserService,
    private viewFactory: ViewFactory,

  ) {
    Chart.register(
      LineElement,
      LineController,
      CategoryScale,
      LinearScale,
      PointElement,
      BarElement,
      BarController,
      Tooltip,
      Legend,
      PieController,
      ArcElement
    );
  }

  ngOnInit(): void {
    const loader = document.getElementById('loader');
    const component = document.getElementById('component');

    if (loader) {
      loader.style.display = 'none';
    }
    if (component) {
      component.style.display = 'block';
    }
    this.getDashboard();
    this.loadHospitals();
    this.createLineGraph();
  this.fetchPatientVisitData()

  }

  ngAfterViewInit() {
    
    this.createLineGraph();

    this.createSalesLineGraph();
    this.createSalesPieChart();
    this.createBarGraphIndicator();
    // this.testReportChart()
  }

  loadHospitals() {
    this.dashboardView
      .getData('/hospitals')
      .then((data: any[]) => {
        this.hospitals = data.map((hospital) => ({
          label: hospital.name,
          value: hospital.id,
        }));
      })
      .catch((error) => {
        console.error('Error fetching hospitals:', error);
      });
  }

 
  createLineGraph() {
    const lineGraphCtx = document.getElementById('linegraph') as HTMLCanvasElement;
    this.dashboardView.getData("/patients-medicine-summary").then((data) => {
      const chartData = {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
          {
            label: 'Medicines Taken',
            data: data.medicines_taken,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
          },
          {
            label: 'Medicines Not Taken',
            data: data.medicines_not_taken,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1,
          },
        ],
      };

      if (this.lineChart) {
        this.lineChart.destroy();
      }

      this.lineChart = new Chart(lineGraphCtx, {
        type: 'line',
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            legend: { display: true },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Months'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Quantity'
              },
              beginAtZero: true
            }
          }
        },
      });

    }).catch((error) => {
      console.error('Error fetching data from API:', error);
    });
  }

  getDashboard() {
    this.dashboardView.getData('/dashboard').then((data: any[]) => {
      this.dashboardData = data;
    }).catch((error) => {
      console.error('Error fetching dashboard data:', error);
    });
  }


  async fetchPatientVisitData() {
    try {
      const response = await fetch(environment.appApi+`/patients-visit-summary`);
      const data = await response.json();
      this.createBarGraph(data.data);
    } catch (error) {
      console.error('Error fetching patient visit data from API:', error);
    }
  }




 
  createBarGraph(visitData: any) { 
    const barGraphCtx = document.getElementById('bargraph') as HTMLCanvasElement;
    if (this.barChart) {
      this.barChart.destroy();
    }
    this.barChart = new Chart(barGraphCtx, {
      type: 'bar',
      data: {
        labels: ['Govt', 'Private'],
        datasets: [
          {
            label: 'Visits (3 Months)',
            data: [
              visitData.visits_3_month.Govt,
              visitData.visits_3_month.Private,
            ],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
          {
            label: 'Visits (6 Months)',
            data: [
              visitData.visits_6_month.Govt,
              visitData.visits_6_month.Private,
            ],
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            min: 0,
            max: 100,
            ticks: {
              stepSize: 10,
            },
          },
          
        },
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    });
  }


  createBarGraphIndicator() {
    const barGraphCtx = document.getElementById('barsgraph') as HTMLCanvasElement;
    new Chart(barGraphCtx, {
      type: 'bar',
      data: {
        labels: ['ACEI', 'P2Y2 receptor inhibitor', 'Statins'],
        datasets: [
          {
            label: '1st Quarter',
            data: [75, 90, 85],
            backgroundColor: 'blue',
          },
          {
            label: '2nd Quarter',
            data: [80, 85, 90],
            backgroundColor: 'orange',
          },
          {
            label: '3rd Quarter',
            data: [85, 80, 75],
            backgroundColor: 'gray',
          },
          {
            label: '4th Quarter',
            data: [90, 95, 100],
            backgroundColor: 'yellow',
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            min: 0,
            max: 120,
            ticks: {
              stepSize: 20,
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              autoSkip: false,
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    });
  }


 
  createSalesLineGraph() {
    const ctx = document.getElementById('salesLineGraph') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        datasets: [
          {
            label: 'Scale',
            data: [15000, 20000, 18000, 22000, 24000, 26000, 30000, 27500],
            borderColor: 'rgb(54, 162, 235)',
            fill: false,
            tension: 0.1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    });
  }

  createSalesPieChart() {
    const ctx = document.getElementById('salesPieChart') as HTMLCanvasElement;
    if (ctx) {
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Thiruvanathapuram', 'Kollam', 'Pathanamthitta'],
          datasets: [
            {
              label: 'Patients',
              data: [30000, 25000, 20000],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        },
      });
    } else {
      console.error('Canvas with id salesPieChart not found');
    }
  }

  // testReportChart() {
  //   const testReportGraphCtx = document.getElementById('testReportLineGraph') as HTMLCanvasElement;
  
  
  //   const testReportData = {
  //     labels: [
  //       'January', 'February', 'March', 'April', 'May', 
  //       'June', 'July', 
  //     ],
  //     datasets: [
  //       {
  //         label: 'Dataset 1',
  //         data: [40, 30, 50, 45, 60, 70, 80, ], 
  //         fill: false,
  //         borderColor: 'rgba(75, 192, 192, 1)', 
  //         tension: 0.1,
  //         pointBackgroundColor: 'rgba(75, 192, 192, 1)', 
  //       },
  //       {
  //         label: 'dataset 2',
  //         data: [20, 25, 15, 30, 40, 35, 30, ], 
  //         fill: false,
  //         borderColor: 'rgba(255, 99, 132, 1)', 
  //         tension: 0.1,
  //         pointBackgroundColor: 'rgba(255, 99, 132, 1)', 
  //       },
  //     ],
  //   };
  
  //   new Chart(testReportGraphCtx, {
  //     type: 'line',
  //     data: testReportData,
  //     options: {
  //       responsive: true,
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //           title: {
  //             display: true,
  //             text: 'Number of Cases', 
  //           },
  //           ticks: {
  //             callback: (tickValue: string | number) => {
  //               return typeof tickValue === 'number' ? tickValue.toFixed(0) : tickValue; 
  //             },
  //           },
  //         },
  //         x: {
  //           title: {
  //             display: true,
  //             text: 'Months', 
  //           },
  //         },
  //       },
  //       plugins: {
  //         legend: {
  //           display: true,
  //         },
  //         tooltip: {
  //           callbacks: {
  //             label: (tooltipItem) => {
  //               return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`; 
  //             },
  //           },
  //         },
  //       },
  //     },
  //   });
  // }
  

}
