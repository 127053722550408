import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class AppHeader {

  static phpLogin = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded');

  constructor() { }

  public static getLoginHeader() {
    if (environment.backend.php) {
      return this.phpLogin;
    } else if (environment.backend.node) {
      return this.getHeader();  // Adjust for Node.js, if needed
    } else {
      return this.getHeader();  // Default fallback
    }
  }

  // Modify to avoid null for Content-Type
  public static getHeader(contentType: string = 'application/json', token: string | null = null) {
    let headers = new HttpHeaders();

    // Add content type header if defined
    if (contentType) {
      headers = headers.set('Content-Type', contentType);
    }

    // If there’s a token (like JWT), include it in the header
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  }
}
