<div class="sidebar" id="sidebar">
  <div class="sidebar-inner slimscroll">
    <div id="sidebar-menu" class="sidebar-menu" (mouseover)="revealMenu()" (mouseleave)="collapseMenu()">
      <ul class="accordion" id="mainSidemenu">
        <li *ngFor="let menu of filteredMenuItems" [class.has-submenu]="menu.submenu"
          [class.open]="isSubmenuOpen(menu.id)">
          <a [routerLink]="menu.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}"
            (click)="toggleSubmenu(menu.id)">
            <i [class]="'i i-' + menu.icon"></i>
            <span>{{ menu.label }}</span>
            <i *ngIf="menu.submenu" class="submenu-toggle"></i>
          </a>
          <!-- Submenu -->
          <ul *ngIf="menu.submenu && isSubmenuOpen(menu.id)" class="submenu show">
            <li *ngFor="let sub of menu.submenu">
              <a [routerLink]="sub.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                {{ sub.label }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="sidebar-overlay"></div>