import { Component } from '@angular/core';
import { UserService } from 'src/app/_common/services/user.service';
import { SysService } from 'src/app/_common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  activeMenuId: string | null = null;
  filteredMenuItems: any[] = [];

  constructor(private userService: UserService,
    public router: Router,
    public sysService: SysService) { }

  ngOnInit() {
    this.userService.getPermissions();
    this.filterMenuItems();
  }

  menuItems = [
    { id: "dashboard", label: 'Dashboard', icon: 'dashboard', link: '/home', requiredPermission: 'addDashboard' },
    { id: "coordinators", label: 'Coordinators', icon: 'doctors', link: '/users', requiredPermission: 'addCoordinator' },
    { id: "patients", label: 'Patients', icon: 'person', link: '/patients', requiredPermission: 'addPatient' },
    { id: "scale", label: 'Scale', icon: 'scale', link: '/scale', requiredPermission: 'addScale' },
    { id: "hospitals", label: 'Hospitals', icon: 'hospital-o', link: '/hospitals', requiredPermission: 'addHospital' },
    { id: "medicines", label: 'Medicine History', icon: 'i i-pills', link: '/medicine', requiredPermission: 'addMedicine' },
    { id: "test", label: 'Test History', icon: 'vial', link: '/tests', requiredPermission: 'viewTests' },
    { id: "infographics", label: 'Infographics', icon: 'subscriptions', link: '/infographics', requiredPermission: 'addInfographics' },
    {
      id: "settings",
      label: 'Settings',
      icon: 'settings',
      requiredPermission: 'addConfiguration',
      submenu: [
        { id: 'Medicine', label: 'Medicine', icon: 'i i-pills', link: '/medicines', requiredPermission: 'addMedicine' },
        { id: 'Tips', label: 'Health Tips', icon: 'i i-pills', link: '/tips/new', requiredPermission: 'addTips' }
      ]
    }
  ];

  // Filter menus based on user permissions
  filterMenuItems() {
    this.filteredMenuItems = this.menuItems
      .filter(item =>
        this.userService.hasPermission(item.requiredPermission) && (!item.submenu || this.filterSubmenu(item.submenu).length > 0)
      )
      .map(item => ({
        ...item,
        submenu: item.submenu ? this.filterSubmenu(item.submenu) : null
      }));
  }

  // Filter submenus based on user permissions
  filterSubmenu(submenu: any[]) {
    return submenu.filter(subItem => this.userService.hasPermission(subItem.requiredPermission));
  }

  link(path: string) {
    SysService.scrollTo(0);
    this.sysService.clear();
    this.router.navigateByUrl("/", { skipLocationChange: false }).then(() => this.router.navigate([path]));
  }

  revealMenu() {
    if (document.querySelector('body')?.classList.contains('mini-sidebar')) {
      document.querySelector('body')?.classList.add('expand-menu');
    } else {
      document.querySelector('body')?.classList.remove('expand-menu');
    }
  }

  collapseMenu() {
    document.querySelector('body')?.classList.remove('expand-menu');
  }

  toggleSubmenu(menuId: string) {
    this.activeMenuId = this.activeMenuId === menuId ? null : menuId;
  }

  isSubmenuOpen(menuId: string): boolean {
    return this.activeMenuId === menuId;
  }
}
