import { Component } from '@angular/core';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  isLoading: boolean = true;
  ngOnInit(): void {
    const loader = document.getElementById('loader');
    const component = document.getElementById('component');

    if (loader) {
      loader.style.display = "block";
    }
    if (component) {
      component.style.display = "none";
    }
  }

  ngAfterViewInit() {
    this.checkLoader();
  }

  checkLoader() {
    this.isLoading = false;
    setTimeout(function () {
      const loader = document.getElementById('loader');
      const component = document.getElementById('component');

      if (loader) {
        loader.style.display = "none";
      }
      if (component) {
        component.style.display = "block";
      }
    }, 2000);
  }
}
