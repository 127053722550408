import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../_common/services/user.service';
import { SysService, View, ViewFactory } from 'src/app/_common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  private headerView: View = this.viewFactory.newView();

  isLoggedIn = false;
  userName: string = '';
  alertData: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    public sysService: SysService,
    private viewFactory: ViewFactory,
  ) { }

  ngOnInit(): void {
    // Check if the user is logged in
    this.isLoggedIn = this.userService.isLoggedIn();
    this.setUserName();
    this.alertNotifications();
  }

  setUserName() {
    const user = localStorage.getItem('auth-user');
    if (user) {
      const userData = JSON.parse(user);
      this.userName = userData.name || 'User';
    }
  }



  toggleSideNav() {
    if (document.querySelector('html')?.classList.contains('menu-opened')) {
      document.querySelector('html')?.classList.remove('menu-opened');
      document.querySelector('body')?.classList.remove('slide-nav');
      document.querySelector('.sidebar-overlay')?.classList.remove('opened');
    } else {
      document.querySelector('html')?.classList.add('menu-opened');
      document.querySelector('body')?.classList.add('slide-nav');
      document.querySelector('.sidebar-overlay')?.classList.add('opened');
    }
  }

  toggleSidebar() {
    if (document.querySelector('body')?.classList.contains('mini-sidebar')) {
      document.querySelector('body')?.classList.remove('mini-sidebar');
    } else {
      document.querySelector('body')?.classList.add('mini-sidebar');
    }
  }


  logout(): void {
    this.userService.logout();
    this.isLoggedIn = false;
    this.router.navigate(['/login']);
  }

  alertNotifications() {
    if (this.sysService.isCoordinator()) {
      const id = this.userService.getUser().hospital_id;
      this.headerView.getData(`/alerts/${id}`).then(data => {
        this.alertData = data.alerts;
      })
    }
  }

  home() {
    if (this.sysService.isAdmin() == true) {
      this.router.navigate(['/home'])
    }
    else {
      this.router.navigate(['/patients'])
    }
  }

}
