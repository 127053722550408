
export const AppConstants = {
    tokenKey: 'auth-token', //key name to store token
    userKey: 'auth-user', //loggedin user details key

    confirm: 'Confirm',

    saveSuccess: 'Succesfully saved!',
    deleteSuccess: 'Succesfully deleted!',
    deleteConfirm: 'Are you sure you want to delete ?',

    saveFailed: 'Failed to save.',
    deleteFailed: 'Failed to delete. Relation exist.',
    selectFailed: 'Failed to select.',
    validationFailed: 'Correct the validation errors.',

    noRecordFound: 'No records found.',

    // regexPassword:'(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}',
    regexPassword: '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}',
    regexEmail: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
    time: '^(0?[1-9]|1[0-2]):([0-5][0-9])\s(?:AM|PM)$ ',





    regexZipCpde: '^[0-9][0-9]*$',
    regexPhonenumber: '^[0-9][0-9]*$',
    regexfive: '^[0-9]*[05]$',
    regexSpecial: '^[a-zA-Z0-9][a-zA-Z0-9 ]*$',
    regexName: '^[a-zA-Z][a-zA-Z ]*$',

    err400: 'System configuration error.',  //400 error message     
    err401: 'Username or password is invalid.',  //401 error message
    err403: 'You are not authorized.',  //403 error message
    err500: 'Something went wrong. please try later.', //500 error message
    err409: 'Email already exists', // 409 error message
    err410: 'Medicine already exists',
    err411: 'Hospital cannot be deleted because it is associated with co-ordinators or patients',
    err412: 'Cannot delete medicine, It is assigned to patients',
    err413: 'Medicine Category already exists',
    err414: 'Cannot delete medicine category; it is assigned to medicines',
    err416: 'Medicine not found for patient ',
    errUnknow: 'Something went wrong.' //Unhandled error message

};

