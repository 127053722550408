<div class="page-wrapper margin-left">
    <img class="text-center logo-img" src="assets/images/mainlogo.png" alt="MaMORs"
        style="height: 100px !important; width: 250px !important;">
</div>
<div class="container">
    <h1>Privacy Policy</h1>

    <p>Effective Date: January 1, 2024</p>

    <h2>1. Introduction</h2>
    <p>Welcome to StrokePreven App. This Privacy Policy outlines how we collect, use, disclose, and safeguard your
        information when you use our website and services. By using our services, you consent to the data practices
        described in this policy. If you do not agree with this Privacy Policy, please do not use our services.</p>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information when you use our services:</p>
    <ul>
        <li><strong>Personal Information:</strong> Information you provide when creating an account, such as your name,
            email address, phone number, and payment details.</li>
        <li><strong>Usage Data:</strong> Information about how you interact with our services, such as IP addresses,
            device type, browser type, and pages visited.</li>
        <li><strong>Health Data:</strong> If you provide us with health-related information to help track or prevent
            strokes, such as medical history or medication adherence data, we take extra care to protect it.</li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>We use the information we collect for the following purposes:</p>
    <ul>
        <li>To provide and improve our services.</li>
        <li>To personalize your experience and provide content that is relevant to you.</li>
        <li>To communicate with you, including sending updates, newsletters, and promotional content (if you have
            consented to receive them).</li>
        <li>To analyze usage data for improving the functionality and security of our services.</li>
        <li>To comply with legal obligations and protect our rights.</li>
    </ul>

    <h2>4. How We Share Your Information</h2>
    <p>We may share your information in the following situations:</p>
    <ul>
        <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers
            who assist us in delivering and improving our services, such as hosting, analytics, and customer support.
        </li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in
            response to valid legal requests from public authorities.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your
            information may be transferred as part of that transaction.</li>
    </ul>

    <h2>5. Data Retention</h2>
    <p>We will retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy,
        unless a longer retention period is required or permitted by law.</p>

    <h2>6. Data Security</h2>
    <p>We take the security of your information seriously and implement reasonable security measures to protect it from
        unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the
        internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

    <h2>7. Your Rights and Choices</h2>
    <p>You have the following rights concerning your personal information:</p>
    <ul>
        <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
        <li><strong>Correction:</strong> You can request that we update or correct any inaccurate or incomplete
            information.</li>
        <li><strong>Deletion:</strong> You can request the deletion of your personal data, subject to certain exceptions
            (e.g., if we need to retain it for legal or legitimate business purposes).</li>
        <li><strong>Opt-Out of Communications:</strong> You can opt out of receiving marketing communications from us by
            following the unsubscribe instructions in those emails or contacting us directly.</li>
    </ul>

    <h2>8. Children’s Privacy</h2>
    <p>Our services are not intended for children under the age of 18. We do not knowingly collect personal information
        from children. If we learn that we have collected personal information from a child under 18, we will take steps
        to delete that information as soon as possible.</p>

    <h2>9. International Users</h2>
    <p>If you are accessing our services from outside the United States, please be aware that your information may be
        transferred to, stored, and processed in the United States, where data protection laws may differ from those in
        your jurisdiction.</p>

    <h2>10. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated
        effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are
        protecting your information.</p>

    <h2>11. Contact Us</h2>
    <p>If you have any questions or concerns regarding this Privacy Policy, or if you would like to exercise any of your
        rights, please contact us at:</p>
    <p>Email: <a href="mailto:medicationadherence2021@gmail.com">medicationadherence2021&#64;gmail.com</a></p>

    <div class="footer">
        <p>&copy; 2024 StrokePreven App. All Rights Reserved.</p>
        <p>By using our services, you agree to our <a href="/terms">Terms of Service</a>.</p>
    </div>
</div>