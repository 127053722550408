<header class="header">
  <nav class="navbar navbar-expand-lg bg-primary py-0">
    <div class="container-fluid">
      <a class="mobile_btn" href="javascript:;" (click)="toggleSideNav()">
        <i class="i i-menu"></i>
      </a>
      <a class="navbar-brand logo" (click)="home()">
        <figure>
          <!-- <i class="i i-mamors_logo"></i> -->
          <img src="../../../../assets/images/mainlogo.png" alt="logo" />
        </figure>
        <!-- <img src="assets/images/Logo.jpg" width="35" height="35" alt="">  -->
        <!-- <span class="i">mamors</span> -->
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
          <i class="i i-more_vert"></i>
        </span>
      </button>

      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <a class="offcanvas-title" id="offcanvasNavbarLabel" href="javascript:;">
            <div class="user">
              <span class="user-img">
                <img class="rounded-circle" src="assets/images/user.jpg" width="24" alt="Admin">
                <span class="status online"></span>
              </span>
              <span>Admin</span>
            </div>

          </a>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-start flex-grow-1">
            <li class="nav-item">
              <a id="toggle_btn" href="javascript:void(0);" (click)="toggleSidebar()"><i class="i i-menu"></i></a>
            </li>
          </ul>
          <ul class="navbar-nav justify-content-end flex-grow-1">
            <!-- <li class="nav-item dropdown">
              <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-expanded="false"><i
                  class="i i-notifications_none"></i>
                <span class="text onlyForMobile">Notifications</span>
                <span class="badge badge-pill bg-danger">0</span></a>
              <div class="dropdown-menu notifications" x-placement="top-start" *ngIf="sysService.isCoordinator()">
                <div class="topnav-dropdown-header">
                  <span>Notifications</span>
                </div>
                <div class="drop-scroll">
                  <ul class="notification-list">
                    <li class="notification-message" *ngFor="let alert of alertData">
                      <a>

                        <div class="media">
                          <div class="media-body">
                            <p class="noti-details">
                              {{ alert.description }}
                            </p>
                            <p class="noti-time"><span class="notification-time">{{ alert.created_at |
                                date:'shortTime'}}</span></p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="topnav-dropdown-footer">
                  <a href="activities.html">View all Notifications</a>
                </div>
              </div>
            </li> -->
            <li class="nav-item dropdown has-arrow">
              <a href="#" class="dropdown-toggle nav-link user-link" data-bs-toggle="dropdown" aria-expanded="false">
                <span class="user-img">
                  <img class="rounded-circle" src="assets/images/user.jpg" width="24" alt="User Image">
                  <span class="status online"></span>
                </span>
                <span>{{ userName | titlecase }}</span>
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" (click)="logout()">Logout</a>
              </div>
            </li>
            <ul class="onlyForMobile">
              <li class="nav-item login">
                <a class="nav-link" (click)="logout()">Logout</a>
              </li>
            </ul>

          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>